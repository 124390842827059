import { Module } from './module';
import { request } from './utils/request';
import { dispatchEvent } from './utils/events';
import type { MCFXTracker, PhoneNumberLease } from './declarations';

const clearSpecialCharsRegex = /[-/\\^$*+?.()|[\]{}]/g;

export class Calltracking extends Module {
  searchPattern: RegExp;
  deferedReplacements: Map<string, Set<Node>>;
  constructor(tracker: MCFXTracker) {
    super(tracker);
    this.tracker = tracker;
    this.deferedReplacements = new Map();

    if (this.tracker.configuration.mode === 'debug') {
      return;
    }

    if (this.tracker.configuration.calltracking) {
      this.init();
    }
  }

  init() {
    this.searchPattern = this.generateRegexForPhoneNumbers(
      Object.keys(this.tracker.configuration.calltracking)
    );

    const { channel, subchannel } = this.tracker.session.get('attribution');

    Object.entries(this.tracker.configuration.calltracking).forEach(([num, config]) => {
      if (false === config.usePool) {
        return;
      }
      // if we there is not a specific scope
      // the target all channels except direct
      if (config.poolScope === null && channel !== 'direct') {
        this.deferedReplacements.set(num, new Set());
        return;
      }

      // if we have a pool scope than only defer number replacement
      // when the channel or subchannel is in the scope
      if (
        config.poolScope &&
        (config.poolScope.includes(channel) ||
          config.poolScope.includes(subchannel) ||
          config.poolScope.includes('any'))
      ) {
        this.deferedReplacements.set(num, new Set());
      }
    });
    this.replaceNumbers(document.body);
    this.replaceDeferredNumbers().then(() => {
      dispatchEvent('calltracking:replaced', this.tracker);
    });
  }

  generateRegexForPhoneNumbers(phoneNumbers) {
    const regexPatterns = phoneNumbers
      .filter((t) => t)
      .map((phoneNumber) => {
        if (/[a-zA-Z]/.test(phoneNumber)) {
          return `(${phoneNumber.replace(clearSpecialCharsRegex, (match) => `\\${match}?`)})`;
        }

        const escapedNumber = phoneNumber.replace(clearSpecialCharsRegex, '');
        // here we are building conditional match options to be sure we acount
        // for multiple format structures. the \\s? additions is to account for when text
        // and numbers are mixed so we don't remove the spaces from surrounding text
        return escapedNumber.length === 10
          ? `(([0-9]{1,3})?\\s?${escapedNumber.slice(-10).split('').join('\\s?')})`
          : `((${escapedNumber.slice(-13, -10)})?\\s?${escapedNumber.slice(-10).split('').join('\\s?')})`;
      });

    return new RegExp(regexPatterns.join('|'), 'g');
  }

  // Function to replace text content and href attributes
  replaceNumbers(node, skipDefered = false) {
    const { channel = '', subchannel = '' } =
      this.tracker.session.get('attribution').originalChannels ?? {};
    // Define the replacement keys in a single regular expression

    // Handle text nodes (nodeType === 3)
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent.replace(clearSpecialCharsRegex, '');

      const newText = text.replace(this.searchPattern, (match) => {
        const matchRef = this.getMatchReference(match);

        // if this is a number we need to defer to pool leasing first
        // we just return the text unless forcing skippingDefered
        if (this.deferedReplacements.has(matchRef) && skipDefered === false) {
          this.deferedReplacements.get(matchRef).add(node);
          return match;
        }

        const matchedNumber = this.tracker.configuration.calltracking[matchRef];
        const replaceAs =
          matchedNumber?.replace.pool ??
          matchedNumber?.replace[subchannel] ??
          matchedNumber?.replace[channel] ??
          matchedNumber?.replace.any;

        const newValue = replaceAs
          ? ` ${this.formatPhoneNumber(replaceAs, matchedNumber.format)}`
          : match;

        return newValue;
      });

      // Only update the DOM if there's a change
      if (newText !== text) {
        node.textContent = newText;
      }
    }

    // Handle element nodes (nodeType === 1)
    else if (node.nodeType === Node.ELEMENT_NODE) {
      // Check if the element has an 'href' attribute with 'tel:' and replace it
      if (node.hasAttribute('href')) {
        const href = node.getAttribute('href');

        // If the href starts with 'tel:', apply the replacements
        if (href.startsWith('tel:')) {
          const newHref = href
            .replace(clearSpecialCharsRegex, '')
            .replace(this.searchPattern, (match) => {
              const matchRef = this.getMatchReference(match);

              if (this.deferedReplacements.has(matchRef) && skipDefered === false) {
                this.deferedReplacements.get(matchRef).add(node);
                return match;
              }

              const matchedNumber = this.tracker.configuration.calltracking[matchRef];

              return `${
                matchedNumber.replace.pool ??
                matchedNumber.replace[subchannel || 'direct'] ??
                matchedNumber.replace[channel] ??
                matchedNumber.replace.any ??
                (match.startsWith('+') ? match : `+${match}`)
              }`;
            });

          // Only update the DOM if there's a change
          if (newHref !== href) {
            node.setAttribute('href', newHref.startsWith('tel:') ? newHref : `tel:${newHref}`);
          }
        }
      }

      // Recursively traverse child nodes
      for (const child of node.childNodes) {
        this.replaceNumbers(child, skipDefered);
      }
    }
  }

  /**
   * Retrieves the reference key for a given phone number match.
   *
   * This method searches for the closest matching key in the configuration
   * based on the provided phone number match. It handles both exact matches
   * and partial matches by checking the length of the keys and the match.
   *
   * @param match - The phone number match to find a reference for.
   * @returns The reference key for the phone number match.
   */
  getMatchReference(match) {
    if (/[a-zA-Z]/.test(match)) {
      return Object.keys(this.tracker.configuration.calltracking).find((number) => {
        return (
          match.replace(clearSpecialCharsRegex, '') === number.replace(clearSpecialCharsRegex, '')
        );
      });
    }

    // all regex leaves spaces intact so when we match our keys
    // which have no spaces we need to remove the spaces.
    const _match = match.replace(/\s/g, '');

    if (this.tracker.configuration.calltracking[_match]) {
      return _match;
    }

    return Object.keys(this.tracker.configuration.calltracking).find((number) => {
      if (_match.length > number.length) {
        return _match.includes(number);
      }
      return number.includes(_match);
    });
  }

  /**
   * Formats a phone number according to a specified format string.
   *
   * The format string uses '#' as a placeholder for digits. Non-digit characters
   * in the format string are preserved in the output.
   *
   * @param number - The phone number to format. It should be a string containing digits.
   * @param format - The format string, where '#' represents a digit.
   * @returns The formatted phone number as a string.
   *
   * @example
   * // Returns "(123) 456-7890"
   * formatPhoneNumber("1234567890", "(###) ###-####");
   *
   * @example
   * // Returns "+1-234-567-8901"
   * formatPhoneNumber("+12345678901", "+#-###-###-####");
   *
   * @example
   * // Returns "123-4567"
   * formatPhoneNumber("1234567", "###-####");
   */
  formatPhoneNumber(number: string, format: string): string {
    // Remove the leading '+' and any non-digit characters
    const cleanedNumber = number.replace(/[^\d]/g, '');

    let formattedValue = '';
    let valueIndex = cleanedNumber.length - 1;

    for (let i = format.length - 1; i >= 0; i--) {
      if (format[i] === '#') {
        if (valueIndex >= 0) {
          formattedValue = cleanedNumber[valueIndex] + formattedValue;
          valueIndex--;
        } else {
          break;
        }
      } else {
        formattedValue = format[i] + formattedValue;
      }
    }

    return formattedValue;
  }

  /**
   * Requests phone number leases from the pool for specified targets.
   *
   * This method makes an API request to lease phone numbers from a pool based on
   * the current visitor's session and attribution data. It:
   * 1. Constructs the request URL using the agent configuration
   * 2. Gathers attribution data from the current session
   * 3. Makes a POST request to lease numbers for the specified targets
   * 4. Filters out any null/undefined leases from the response
   *
   * @param targets - Array of target IDs to lease phone numbers for
   * @returns Promise resolving to an array of PhoneNumberLease objects
   *
   * @throws Returns empty array if the API request fails
   *
   * @example
   * // Request leases for multiple targets
   * const leases = await calltracking.leasePoolNumbers(['target1', 'target2']);
   */
  async leasePoolNumbers(targets): Promise<PhoneNumberLease[]> {
    try {
      const url = `${this.tracker.configuration.agentUrl}/calltracking/pool-lease`;
      const {
        channel = '',
        subchannel = '',
        source,
        medium,
        ad,
      } = this.tracker.session.get('attribution');

      const leases = await request<PhoneNumberLease[]>(url).post({
        action: 'get-lease',
        siteId: this.tracker.configuration.siteId,
        visitorId: this.tracker.session.get('uid'),
        sessionId: this.tracker.session.get('sid'),
        targets,
        channel,
        subchannel,
        source,
        medium,
        ad,
      });

      return leases.filter((lease) => lease);
    } catch (_err) {
      return [];
    }
  }

  /**
   * Replaces phone numbers that were deferred for pool number assignment.
   *
   * This method handles the asynchronous replacement of phone numbers that require
   * pool number leasing. It:
   * 1. Creates a mapping of targetIds to their corresponding phone numbers
   * 2. Requests leased numbers from the pool for each target
   * 3. Updates the configuration with leased numbers
   * 4. calls replaceNumbers to perform the actual DOM replacements
   *
   * @returns {Promise<void>} A promise that resolves when all deferred numbers have been replaced
   *
   * @example
   * // Internally called after initialization
   * await calltracking.replaceDeferredNumbers();
   */
  async replaceDeferredNumbers() {
    if (this.deferedReplacements.size === 0) {
      return;
    }
    const tracker = this.tracker;

    // creates a targetId to "number config" map
    const poolMap = Array.from(this.deferedReplacements.keys()).reduce((acc, number) => {
      acc[tracker.configuration.calltracking[number].targetId] = [
        ...(acc[tracker.configuration.calltracking[number].targetId] ?? []),
        number,
      ];
      return acc;
    }, {});

    // need to replace this with a real api call
    const leasedNumbers = await this.leasePoolNumbers(Object.keys(poolMap));

    // loop through the leases and set leased number to the config.
    leasedNumbers.forEach(({ targetId, number }) => {
      poolMap[targetId].forEach((targetNumber) => {
        tracker.configuration.calltracking[targetNumber].replace.pool = number;
      });
    });

    // loop through our defe
    this.deferedReplacements.forEach((nodes) => {
      nodes.forEach((node) => {
        this.replaceNumbers(node, true);
      });
    });
  }
}

// Add this service to the service type index
declare module './declarations' {
  interface McfxModules {
    ['calltracking']: Calltracking;
  }
}
